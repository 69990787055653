define(['app', '$console'], function (app, $console) {

  var productConfidenceIcons = function () {
    var component = {};

    var _config = {
      selectors: {
        clickableElement: '[data-event]',
        confidenceIcon: '[data-confidence-icon]'
      },
      label: {
        confidenceIconComponentClicked: 'productConfidenceIcon component clicked',
        clicked: 'Clicked'
      }
    };

    var _init = function (element) {
      component.element = element;

      var icons = component.element.querySelectorAll(component.config.selectors.confidenceIcon);

      for (var index = 0; index < icons.length; index++) {
        icons[index].addEventListener('click', component.trackIconClicked);
      }

      component.clickableElement = component.element.querySelector(component.config.selectors.clickableElement);
      component.createShopperApproved();
      if (component.clickableElement) {
        component.clickableElement.addEventListener('contextmenu', component.rightClickEvent, false);
      }
      return component;
    };

    var _createShopperApproved = function() {
      var scriptTag = window.document.createElement('script');
      scriptTag.src = '//www.shopperapproved.com/seals/certificate.js';
      scriptTag.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    };

    var _rightClickEvent = function(event) {
      event.preventDefault();
      $console.error('Copying Prohibited by Law - This image and all included logos' +
        ' are copyrighted by Shopper Approved ©' + new Date().getFullYear() + '.');
      return false;
    };

    var _trackIconClicked = function () {
      app.publish('tracking/record', 'Product | Confidence icon', component.config.label.clicked,
        component.config.label.confidenceIconComponentClicked);
    };

    component.init = _init;
    component.config = _config;
    component.createShopperApproved = _createShopperApproved;
    component.rightClickEvent = _rightClickEvent;
    component.trackIconClicked = _trackIconClicked;

    return component;
  };

  return productConfidenceIcons;
});
